<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>群組保護</b-breadcrumb-item>
            <b-breadcrumb-item active>成員名單設定</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">成員名單設定</h4>

        <div class="row d-flex justify-content-between align-items-center">
          <div
            class="col-12 col-xl-4 d-flex align-items-center flex-nowrap my-3"
          >
            <h6 class="mb-xl-0 font-weight-bold text-nowrap mr-2">群組</h6>
            <b-form-select
              v-model="currentGroup"
              :options="
                groups.map((x) => {
                  return { text: `${x.branch_code} ${x.name}`, value: x.id };
                })
              "
              @change="fetchCustomers"
            >
              <template v-if="groups.length != 1" #first>
                <b-form-select-option value=""
                  >-- 請選擇 --</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>

          <div class="col-12 col-xl-4 d-flex my-3 align-items-center">
            <h6 class="mb-2 mr-2 mb-xl-0 font-weight-bold flex-shrink-0">
              角色
            </h6>

            <AppMultiSelect
              v-model="selectedRoles"
              :options="roleOptions"
              @input="fetchCustomers"
            />
          </div>

          <div
            class="
              col-12 col-xl-4
              d-flex
              flex-column flex-xl-row
              align-items-start align-items-xl-center
              my-3
            "
          >
            <b-input-group class="ml-0 ml-xl-4">
              <b-form-input
                placeholder="搜尋姓名/平台ID"
                v-model="keyword"
                v-on:keyup.enter="fetchCustomers"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="fetchCustomers"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>

          <div
            class="col-12 col-xl-4 d-flex align-items-center flex-nowrap my-3"
          >
            <h6 class="mb-xl-0 font-weight-bold text-nowrap mr-2">設定角色</h6>
            <b-form-select
              :options="
                roleOptions.filter((x) => {
                  return x.value != 'guard';
                })
              "
              v-model="setRole"
            >
              <b-form-select-option :value="null"
                >-請選擇角色-</b-form-select-option
              >
            </b-form-select>

            <b-button
              variant="primary"
              class="ml-2 text-nowrap"
              @click="setRoleToCustomers"
              :disabled="setRole == null || selectedCustomers.length == 0"
              >設定</b-button
            >
          </div>
        </div>

        <hr />
        <b-tabs content-class="mt-3">
          <b-tab title="店內成員" active>
            <div class="font-weight-bold">群組人數：{{ customers.length }}</div>

            <b-table responsive striped hover :items="customers" :fields="fields">
              <template #cell(id)="data">
                <b-form-checkbox
                  :value="data.item.id"
                  v-model="selectedCustomers"
                  :disabled="data.item.role == 'black_global' || data.item.role == 'guard'"
                />
              </template>
              <template #cell(name)="data">
                {{ data.item.name }}
              </template>
              <template #cell(avatar_url)="data">
                <b-img-lazy
                  v-bind="mainProps"
                  v-show="!!data.item.avatar_url"
                  :src="data.item.avatar_url"
                  class="rounded-circle"
                ></b-img-lazy>
              </template>
              <template #cell(role)="data">
                <b-badge :variant="roleEnum(data.item.role).variant">{{
                  roleEnum(data.item.role).text
                }}</b-badge>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="廣告黑名單（全店）">
            <b-table responsive striped hover :items="globalBlackCustomers" :fields="blackFields">
              <template #cell(avatar_url)="data">
                <b-img-lazy
                  v-bind="mainProps"
                  v-show="!!data.item.avatar_url"
                  :src="data.item.avatar_url"
                  class="rounded-circle"
                ></b-img-lazy>
              </template>
              <template #cell(role)="data">
                <b-badge :variant="roleEnum(data.item.role).variant">{{
                  roleEnum(data.item.role).text
                }}</b-badge>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";
import guardApi from "@/apis/guard";
import RoleChecker from "@/utils/RoleChecker";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import AppMultiSelect from "@/components/AppMultiSelect";

export default {
  components: {
    AppMultiSelect,
  },
  data() {
    return {
      roleOptions: [
        { text: "一般", value: "" },
        { text: "單店黑名單", value: "black" },
        { text: "廣告黑名單(全店)", value: "black_global" },
        { text: "管理員", value: "admin" },
        { text: "衛兵", value: "guard" },
      ],
      currentGroup: "",
      consts,
      showLoading: true,
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
      fields: [
        {
          key: "id",
          label: "勾選",
        },
        {
          key: "avatar_url",
          label: "頭貼",
        },
        {
          key: "name",
          label: "姓名",
        },
        {
          key: "role",
          label: "角色",
        },
        {
          key: "outer_code",
          label: "平台ID",
        },
        {
          key: "join_at",
          label: "加入日期",
          formatter: (value) => {
            return value
              ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
          sortable: true,
        },
        {
          key: "leave_at",
          label: "離開日期",
          formatter: (value) => {
            return value
              ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
          sortable: true,
        },
      ],
      blackFields: [
        { key: "avatar_url", label: "頭貼", },
        { key: "name", label: "姓名", },
        { key: "role", label: "角色", },
        { key: "outer_code", label: "平台ID", },
        {
          key: "join_at",
          label: "加入日期",
          formatter: (value) => {
            return value
              ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
          sortable: true,
        },
        {
          key: "leave_at",
          label: "離開日期",
          formatter: (value) => {
            return value
              ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
          sortable: true,
        },
      ],
      customers: [],
      globalBlackCustomers: [],
      selectedRoles: this.roleOptions,
      selectedCustomers: [],
      setRole: null,
      groups: [],
      keyword: null,
    };
  },
  mounted() {
    this.selectedRoles = this.roleOptions;
    this.fetchGuardGroups();
    this.fecthGlobalBlackCustomers();
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  methods: {
    async fetchGuardGroups() {
      this.showLoading = true;

      try {
        const { data } = await guardApi.getActiveGuardGroups();
        this.groups = data.data;

        if (this.groups.length == 1) {
          this.currentGroup = this.groups[0].id;
          this.fetchCustomers();
        }
      } catch (error) {
        console.error(error);
        alert("取得群組資料錯誤");
      }
      this.showLoading = false;
    },
    async fetchCustomers() {
      if (this.currentGroup) {
        this.selectedCustomers = [];
        this.showLoading = true;

        try {
          const params = {};

          if (this.keyword) params.keyword = this.keyword;

          params.roles = this.selectedRoles.map(({ value }) => value);

          const { data } = await guardApi.getCustomersOfGuardGroup(
            this.currentGroup,
            params
          );

          this.customers = data.data;
        } catch (error) {
          console.error(error);
          alert("取得群組資料錯誤");
        }
        this.showLoading = false;
      }
    },
    async fecthGlobalBlackCustomers() {
      this.showLoading = true;

      try {
        const { data } = await guardApi.getGlobalBlackCustomersOfGuardGroup({
          keyword: this.keyword,
        });

        this.globalBlackCustomers = data.data;
      } catch (error) {
        console.error(error);
        alert("取得廣告黑名單（全店）錯誤");
      }
      this.showLoading = false;
    },
    async setRoleToCustomers() {
      let customerIds = this.selectedCustomers;
      let roleName = this.roleOptions.find((option) => option.value == this.setRole)?.text;

      // 先跟後端檢查，看看是否有名單內的人是需要過濾的
      let beforeCheck = await guardApi.checkBeforeSetRoleToCustomer(this.currentGroup, {
        customer_ids: customerIds,
        role: this.setRole == "" ? null : this.setRole,
      });

      let hintCustomerNames = beforeCheck.data?.result?.hint_customer_names ?? [];
      if (hintCustomerNames && hintCustomerNames.length > 0) {
        // 如果發現 hint 名單有值，則顯示提示 confirm 訊息
        let confirmMessage = `以下人員為其他分店管理員<br>不可設定為${roleName}<br>`;

        for (let i = 0; i < hintCustomerNames.length; i ++) {
          confirmMessage += `${hintCustomerNames[i]}<br>`;
        }

        confirmMessage += `<br>確認後系統僅會將其他選取對象設定為${roleName}`;

        this.$swal.fire({
          html: confirmMessage,
          title: '角色設定錯誤',
          showCancelButton: true,
          cancelButtonText: "取消",
          confirmButtonText: "確認",
        }).then(async (result) => {
          if (!result.value) {
            return
          }
          this.callSetCustomerRoleApi(beforeCheck.data.result.filtered_customer_ids);
        });

        return;
      }

      this.$swal.fire({
        text: `請問是否將選取人員設定為 ${roleName}`,
        showCancelButton: true,
        cancelButtonText: "取消",
        confirmButtonText: "確認",
      }).then(async (result) => {
        if (!result.value) {
          return
        }
        this.callSetCustomerRoleApi(this.selectedCustomers)
      });
    },

    async callSetCustomerRoleApi(customerIds) {
      try {
        let response = await guardApi.setRoleToCustomers(this.currentGroup, {
          customer_ids: customerIds,
          role: this.setRole == "" ? null : this.setRole,
        });

        if (
          response.status &&
          response.status === 200 &&
          response.data.success
        ) {
          this.$swal.fire({
            title: "更新成功",
            type: "success",
          });

          this.fetchCustomers();
          this.fecthGlobalBlackCustomers();
        } else {
          alert("角色設定錯誤");
        }
      } catch (error) {
        if (error.response?.data?.message) {
          this.$swal.fire({
            type: "error",
            text: error.response.data.message,
            title: "角色設定錯誤"
          });
        } else {
          this.$swal.fire({
            type: "error",
            title: "角色設定錯誤"
          });
        }
      }
    },

    roleEnum(role) {
      if (role === "admin") {
        return {
          text: "管理員",
          variant: "danger",
        };
      } else if (role === "guard") {
        return {
          text: "衛兵",
          variant: "success",
        };
      } else if (role === "black") {
        return {
          text: "單店黑名單",
          variant: "dark",
        };
      } else if (role === "black_global") {
        return {
          text: "廣告黑名單(全店)",
          variant: "dark",
        };
      } else {
        return {
          text: "一般",
          variant: "secondary",
        };
      }
    },
    checkRole(roles) {
      const checker = new RoleChecker();
      return checker.check(roles);
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
